<template>
    <div class="team">
        <el-table :data="tableData" border style="width: 100%" v-loading="loading" key='1' :height="tableHeight"
            ref="table" :header-cell-style="{background:'#333',color:'#fff'}">
            <el-table-column fixed prop="real_name" label="姓名" align="center">
            </el-table-column>
            <el-table-column label="头像" align="center">
                <template slot-scope="scope">
                    <img :src="QINIU_CDN_HOST + scope.row.head_img" alt="" class="head-img">
                </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号" align="center">
            </el-table-column>
            <el-table-column prop="role_name" label="身份" align="center">
            </el-table-column>
            <el-table-column prop="is_show" label="显示在招商中心" align="center">
            </el-table-column>
            <el-table-column prop="created_time" label="加入时间" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="add" @click='handleAdd'>添加</div>
        <div class="add-content" v-if="addIsShow">
            <div class="add-top">
                <img src="../../assets/image/manage_return_icon.png" alt="" class="add-return" @click="handleReturn">
                <div class="add-title">添加员工</div>
            </div>
            <div class="content-box">
                <div class="box-title">外显职位</div>
                <el-input placeholder="请输入职位" v-model="role_name" clearable class="box-value" style="width:100%">
                </el-input>
            </div>
            <div class="content-box">
                <div class="box-title">所属角色</div>
                <el-select v-model="role_id" clearable placeholder="请选择" style="width:100%">
                    <el-option v-for="item in roleOptions" :key="item.id" :label="item.role_name" :value="item.id"
                        class="box-value">
                    </el-option>
                </el-select>
            </div>
            <div class="content-box">
                <div class="box-title">招商中心</div>
                <el-select v-model="isShow" clearable placeholder="请选择" style="width:100%">
                    <el-option v-for="item in showOption" :key="item.show" :label="item.value" :value="item.show"
                        class="box-value">
                    </el-option>
                </el-select>
            </div>
            <div class="box-empty"></div>
            <div class="phone-content">
                <div class="content-box">
                    <div class="box-title">手机号码</div>
                    <el-input placeholder="请输入手机号码" v-model="phone" clearable class="box-value box-small-value"
                        @input="checkLength" style="width:100%" type="number">
                    </el-input>
                    <!-- <i class="el-icon-plus success-icon" @click="handleAddPhone"></i> -->
                </div>
                <!-- <template v-if="phoneOptions">
                    <div class="content-box" v-for="(item ,index) in phoneOptions" :key="index">
                        <div class="box-title">手机号码</div>
                        <el-input placeholder="请输入手机号码" v-model="item.phone" clearable class="box-value box-small-value"
                            @input="checkLength" style="width:100%" type="number">
                        </el-input>
                        <i class="el-icon-minus success-icon" @click="handleDeletePhone(index)"></i>
                    </div>
                </template> -->
            </div>
            <div class="submit" @click="handleAddUser">确 定</div>
        </div>
        <div class="pop" v-if="addIsShow || editIsShow"></div>
        <div class="edit-content" v-if="editIsShow">
            <div class="add-top">
                <img src="../../assets/image/manage_return_icon.png" alt="" class="add-return" @click="handleReturn">
                <div class="add-title">编辑员工</div>
            </div>
            <el-form>
                <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input type="text" v-model="userInfo.real_name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth">
                    <el-input type="number" v-model="userInfo.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth" class="head-content">
                    <img :src="QINIU_CDN_HOST + userInfo.head_img" alt="" class="head-img">
                    <el-button :loading="loadingUpload" id="upload-banner-img" type="primary">
                        上传图片
                        <i class="el-icon-upload el-icon--right"></i>
                    </el-button>
                </el-form-item>
                <el-form-item label="外显职位" :label-width="formLabelWidth">
                    <el-input type="text" v-model="userInfo.title" placeholder="请输入职位"></el-input>
                </el-form-item>
                <el-form-item label="所属角色" :label-width="formLabelWidth">
                    <el-select v-model="userInfo.role_id" clearable placeholder="请选择" style="width:100%">
                        <el-option v-for="item in roleOptions" :key="item.id" :label="item.role_name" :value="item.id"
                            class="box-value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="招商中心" :label-width="formLabelWidth">
                    <el-select v-model="userInfo.is_show" clearable placeholder="请选择" style="width:100%">
                        <el-option v-for="item in showOption" :key="item.show" :label="item.value" :value="item.show"
                            class="box-value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="submit" @click="handleEditUser">确 定</div>
        </div>
        <!-- 删除员工 -->
        <el-dialog width="500px" top="3vh" :visible.sync="deleteUserVisible" @close="_userDelete">
            <div class="user-info" v-if="deleteUser.real_name">
                {{deleteUser.real_name}} {{deleteUser.phone}}
            </div>
            <div class="trans-content">
                <div class="title">客户移交</div>
                <div class="transfer">
                    <div class="transfering">维护中的客户 {{customerNum}}<span>--------</span> </div>
                    <el-select filterable v-model="transfer_id" placeholder="请选择移交人">
                        <el-option v-for="item in transferOptions" :key="item.id" :label="item.real_name"
                            :value="item.id"></el-option>
                    </el-select>
                </div>
                <div class="tips">
                    <div class="title">提示</div>
                    <div class="text">以下项目直接展示招商电话，请近况设置招商人员</div>
                    <div class="building" v-for="(build, index) in transferBuildings" :key="index">{{build.name}}</div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="_userDelete">取 消</el-button>
                <el-button type="primary" @click="deleteConfirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    let photoUploader;
    // import qiniu from "qiniu.js";
    // qiniu.config({
    //     AccessKey: "B5fc1DR4wwYHqr8Ri7QcYyp_WVxSkOEt6YHRzbH2",
    //     SecretKey: "FSPDrp4FPrrtWt9nDoIAgrAdQRvGhyBI0p3qNcJP"
    // });
    // var imagesBucket;
    const reg = /^1\d{10}$/
    // const validPhone = (rule, value, callback) => {
    //     if (!value) {
    //         callback(new Error('请输入电话号码'))
    //     } else if (!reg.isvalidPhone(value)) {
    //         callback(new Error('请输入正确手机号码'))
    //     } else {
    //         callback()
    //     }
    // }
    export default {
        name: 'manage-team',
        data() {
            return {
                tableData: [],
                tableHeight: 'calc(100vh - 240px)',
                loading: false,
                addIsShow: false,
                editIsShow: false,
                roleOptions: [],
                role_id: '',
                role_name: '',
                phone: '',
                phoneOptions: [],
                showOption: [
                    {
                        show: true,
                        value: "显示该员工"
                    },
                    {
                        show: false,
                        value: "不显示该员工"
                    }
                ],
                isShow: '',
                userInfo: {},
                formLabelWidth: '20%',
                loadingUpload: false,
                bannerBind: false,
                user_id: '',
                deleteUser: {},
                deleteUserVisible: false,
                transferBuildings: [],
                transferOptions: [],
                customerNum: 0,
                transfer_id: '',
                buildingId: '',
                // rules: {
                //     phone: [
                //         { required: true, trigger: 'blur', validator: validPhone }
                //     ]
                // }

            }
        },
        methods: {
            _userDelete() {
                this.deleteUserVisible = false;
                this.transfer_id = ''
            },
            deleteConfirm() {
                if (this.transfer_id != '') {
                    this.$axios({
                        method: 'post',
                        url: 'api/v5/panorama/admin/team/userInfo',
                        data: {
                            type: 'delete',
                            user_id: this.user_id,
                            transfer_user_id: this.transfer_id
                        },
                        headers: {
                            "panorama-api-token": sessionStorage.getItem('token')
                            // "panorama-api-token": 'e6965432851d96da99c91138a21f3088'
                        }
                    }).then(res => {
                        this.deleteUserVisible = false;
                        this.getData()
                    })
                } else {
                    this.$message({
                        message: '请选择移交人',
                        type: 'warning'
                    });
                }

            },
            checkLength(e) {
                if (e.length > 11) {
                    this.phone = e.slice(0, 11)
                }
            },

            getData() {
                this.loading = true
                this.$axios({
                    method: 'POST',
                    url: 'api/v5/panorama/admin/team/list',
                    headers: {
                        "panorama-api-token": sessionStorage.getItem('token')
                        // "panorama-api-token": 'e6965432851d96da99c91138a21f3088'
                    }
                }).then(res => {
                    this.loading = false
                    this.tableData = res.data.data
                })
            },
            handleEdit(r) {
                this.bindQiniu()
                this.editIsShow = true
                this.user_id = r.id
                this.$axios({
                    method: 'post',
                    url: 'api/v5/panorama/admin/team/userInfo',
                    data: {
                        type: 'details',
                        user_id: r.id
                    },
                    headers: {
                        "panorama-api-token": sessionStorage.getItem('token')
                        // "panorama-api-token": 'e6965432851d96da99c91138a21f3088'
                    }
                }).then(res => {
                    this.userInfo = res.data.data.user
                    this.roleOptions = res.data.data.role
                })
            },
            handleDelete(r) {
                this.user_id = r.id
                this.$axios({
                    method: 'post',
                    url: 'api/v5/panorama/admin/team/deleteUserData',
                    data: {
                        user_id: r.id
                    },
                    headers: {
                        "panorama-api-token": sessionStorage.getItem('token')
                        // "panorama-api-token": 'e6965432851d96da99c91138a21f3088'
                    }
                }).then(res => {
                    if (res.data.status == 200) {
                        this.deleteUser = res.data.data.user;
                        this.transferOptions = res.data.data.transferUsers;
                        this.customerNum = res.data.data.customerNum;
                        this.transferBuildings = res.data.data.buildings;
                        this.deleteUserVisible = true;
                    }
                })
            },
            handleEditUser() {
                this.$axios({
                    method: 'post',
                    url: 'api/v5/panorama/admin/team/userInfo',
                    data: {
                        type: 'edit',
                        phone: this.userInfo.phone,
                        real_name: this.userInfo.real_name,
                        title: this.userInfo.title,
                        head_img: this.userInfo.head_img,
                        role_id: this.userInfo.role_id,
                        is_show: this.userInfo.is_show,
                        user_id: this.user_id
                    },
                    headers: {
                        "panorama-api-token": sessionStorage.getItem('token')
                        // "panorama-api-token": 'e6965432851d96da99c91138a21f3088'
                    }
                }).then(res => {
                    if (res.data.status == '200') {
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                        this.editIsShow = false
                        this.getData()
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                })
            },
            //添加员工
            handleAddUser() {
                this.isSubmit = true
                // if (this.phoneOptions.length > 0) {
                //     this.phoneOptions.forEach(item => {
                //         if (item.phone && reg.test(item.phone)) {
                //             this.phone = this.phone + ',' + item.phone
                //         } else if (!reg.test(item.phone)) {
                //             this.isSubmit = false
                //         }
                //     })
                // }
                if (this.isSubmit && reg.test(this.phone)) {
                    this.$axios({
                        method: 'post',
                        url: 'api/v5/panorama/admin/team/userInfo',
                        data: {
                            type: 'addMember',
                            phone: this.phone,
                            title: this.role_name,
                            role_id: this.role_id,
                            is_show: this.isShow,
                            building_id: this.buildingId
                        },
                        headers: {
                            "panorama-api-token": sessionStorage.getItem('token')
                            // "panorama-api-token": 'e6965432851d96da99c91138a21f3088'
                        }
                    }).then(res => {
                        if (res.data.status == '200') {
                            this.$message({
                                message: res.data.msg,
                                type: 'success'
                            });
                            this.addIsShow = false
                            this.getData()
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                }else{
                    this.$message({
                        message: '手机号格式错误',
                        type: 'warning'
                    })
                }

            },
            handleClear() {
                this.role_name = ''
                this.role_id = ''
                this.isShow = ''
                this.phone = ''
                this.phoneOptions = []
            },
            handleReturn() {
                this.addIsShow = false
                this.editIsShow = false
            },
            handleAdd() {
                this.addIsShow = !this.addIsShow
                if (this.addIsShow) {
                    this.handleClear()
                    this.$axios({
                        method: 'post',
                        url: 'api/v5/panorama/admin/team/userInfo',
                        data: {
                            type: 'role'
                        },
                        headers: {
                            "panorama-api-token": sessionStorage.getItem('token')
                            // "panorama-api-token": 'e6965432851d96da99c91138a21f3088'
                        }
                    }).then(res => {
                        this.roleOptions = res.data.data
                    })
                }
            },
            //增加手机号
            handleAddPhone() {
                this.phoneOptions.push({
                    phone: ''
                })
                console.log(this.phoneOptions)
            },
            //删除手机号
            handleDeletePhone(index) {
                this.phoneOptions.splice(index, 1)
            },
            bindQiniu() {
                let app = this;
                app.$axios
                    .get("get-qiniu")
                    .then(res => {
                        let token = res.data
                        photoUploader = Qiniu.uploader({
                            runtimes: "html5,flash,html4",
                            browse_button: "upload-banner-img",
                            uptoken: token,
                            domain: "https://static.comegogogo.com/", //bucket 域名，下载资源时用到，**必需**
                            get_new_uptoken: false, //设置上传文件的时候是否每次都重新获取新的token
                            max_file_size: "100mb", //最大文件体积限制
                            flash_swf_url:
                                "https://static.comegogogo.com/cdn/vuejs/qiniu/Moxie.swf", //引入flash
                            max_retries: 0, //上传失败最大重试次数
                            multi_selection: false,
                            filters: {
                                max_file_size: "10mb",
                                prevent_duplicates: true,
                                mime_types: [{ title: "立即生成", extensions: "png,jpg,jpeg" }]
                            },
                            dragdrop: false, //开启可拖曳上传
                            chunk_size: 0, //分块上传时，每片的体积
                            auto_start: true, //选择文件后自动上传，若关闭需要自己绑定事件触发上传
                            init: {
                                // eslint-disable-next-line
                                FilesAdded: function (up, files) {
                                    app.uploading = 1;
                                },
                                // eslint-disable-next-line
                                BeforeUpload: function (up, file) {
                                    // 每个文件上传前,处理相关的事情
                                },
                                // eslint-disable-next-line
                                UploadProgress: function (up, file) {
                                    // 每个文件上传时,处理相关的事情
                                },
                                // eslint-disable-next-line
                                FileUploaded: function (up, file, info) {
                                    let res = JSON.parse(info);
                                    app.userInfo.head_img = res.key;
                                    photoUploader.removeFile(file);
                                    app.uploading = 0;
                                    // var sourceLink = domain + res.key; 获取上传成功后的文件的Url
                                },
                                // eslint-disable-next-line
                                Error: function (up, err, errTip) {
                                    //上传出错时,处理相关的事情
                                },
                                // eslint-disable-next-line
                                UploadComplete: function () {
                                    //队列文件处理完毕后,处理相关的事情
                                },
                                // eslint-disable-next-line
                                Key: function (up, file) {
                                    let name = file.name;
                                    let key =
                                        "manage/article/pic/" +
                                        new Date().getFullYear() +
                                        "" +
                                        (new Date().getMonth() + 1) +
                                        "" +
                                        new Date().getDate() +
                                        "" +
                                        new Date().getHours() +
                                        "" +
                                        new Date().getMinutes() +
                                        "" +
                                        (Math.floor(Math.random() * 900) + 100) +
                                        '.png';
                                    return key;
                                }
                            }
                        });
                    })
                    .catch(err => {
                        console.log(err);
                    });

            }
        },
        created() {
            this.getData()
            this.buildingId = sessionStorage.getItem('buildingId')
        },
    }
</script>
<style>

</style>
<style lang='scss' scoped>
    @keyframes fadeOutLeft {
        0% {
            right: -600px;
        }

        100% {
            right: 0;
        }
    }

    .team {
        padding-top: 20px;
    }

    #upload-banner-img {
        margin-left: 20px;
    }

    .user-info {
        font-size: 16px;
        font-weight: bold;
        color: #333;
    }

    .trans-content {
        .title {
            margin: 40px 0 10px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
        }
    }

    .transfer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .transfering {
            width: 50%;

            span {
                /* display: inline-block; */
                margin: 0 10%;
            }
        }
    }

    .head-img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        vertical-align: middle;
    }

    .add {
        margin-top: 20px;
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        background: #333;
        cursor: pointer;
    }

    .add-top {
        margin-bottom: 40px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .add-return {
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .add-title {
            font-size: 22px;
            font-weight: 600;
            letter-spacing: 3px;
        }
    }

    .add-content,
    .edit-content {
        position: absolute;
        padding: 40px 80px 40px 40px;
        top: 0;
        right: 0;
        width: 600px;
        height: 100vh;
        background: #fff;
        z-index: 23;
        animation: fadeOutLeft .5s 1;



        .phone-content {
            padding: 0 40px 0 0;
            height: 300px;
            overflow-y: auto;
            /* overflow-x: inherit; */
        }

        .content-box {
            position: relative;
            margin: 30px 0;
            display: flex;
            align-items: center;

            .box-title {
                display: inline-block;
                margin-right: 20px;
                width: 30%;
                font-size: 16px;
            }

            .box-value {
                width: calc(70% - 20px);

            }

            .success-icon {
                position: absolute;
                top: calc(50% -15px);
                right: -40px;
                width: 30px;
                height: 30px;
                font-size: 30px;
                z-index: 4444;
                cursor: pointer;
            }
        }

        .box-empty {
            margin: 70px 0;
            width: 100%;
            height: 1px;
            background: #f1f1f1;
        }

        .submit {
            position: absolute;
            bottom: 60px;
            left: calc(50% - 200px);
            width: 400px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            background: #333;
            cursor: pointer;
        }
    }

    .pop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .3);
        z-index: 22;
    }
</style>